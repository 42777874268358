<template>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <form @submit.prevent="checkCode(code)" class="form-inline">
        <div class="input-group">
          <div class="col-md-3>">
            <label for="code">Kod:</label><br/>
            <input v-model="code" type="text" class="form-control" id="code">
          </div>
          <div class="col-md-3>">
            <br/>
            <button type="submit" class="btn btn-primary">Sprawdź</button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-3"></div>
  </div>
  <br/><br/>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6 text-start">
      <template v-if="validationResult">
        <h3>Wynik walidacji</h3>
        <hr>
        <p><b>Czy kod może być użyty:</b> {{ validationResult.isCodeCanBeUsed ? 'Tak' : 'Nie' }}</p>
        <p><b>Info:</b> {{ validationResult.message }}</p>
        <template v-if="Object.keys(codeData).length">
          <h3>Informacje o kodzie:</h3>
          <hr>
          <p><b>Kod ID:</b> {{ codeData.id }}</p>
          <p><b>Pakiet sprzedażowy - ID:</b> {{ codeData.salesPackage.id }}</p>
          <p><b>Pakiet sprzedażowy - nazwa:</b> <span class="fm-link pointer"
                                                      v-on:click="redirectToSalesPackageByName(codeData.salesPackage.name)">
            {{ codeData.salesPackage.name }}
          </span>
          </p>
          <p><b>Historia użycia kodu:</b></p>
          <template v-if="codeData.usageHistory.length">
            <table id="sales-packages" class="list-table">
              <tr>
                <th style="width: 2%;">ID</th>
                <th style="width: 5%;">Username</th>
                <th style="width: 5%;">Email</th>
                <th style="width: 10%;">Used At</th>
              </tr>
              <template v-for="usedCode in codeData.usageHistory" v-bind:key="usedCode.id">
                <tr>
                  <td>{{ usedCode.id }}</td>
                  <td>{{ usedCode.user.username }}</td>
                  <td>{{ usedCode.user.email }}</td>
                  <td>{{ usedCode.usedAt }}</td>
                </tr>
              </template>
            </table>
          </template>
        </template>
      </template>
    </div>
    <div class="col-md-3"></div>
  </div>
</template>

<script>
import { SalesPackageCodeService } from '../../../services/admin/SalesPackage/salesPackageCodeService';
import { SalesPackageService } from '../../../services/admin/SalesPackage/salesPackageService';

export default {
  name: 'CheckCode',
  data() {
    return {
      code: null,
      validationResult: null,
      codeData: null
    };
  },
  methods: {
    async checkCode() {
      const response = await SalesPackageCodeService.checkCode(this.code);
      const code = response.data;
      this.validationResult = code.validationResult;
      this.codeData = code.salesPackageCode.items[0];
      console.log(this.validationResult);
      console.log(Object.keys(this.validationResult).length);
      console.log(this.codeData);
      console.log(Object.keys(this.codeData).length);
    },
    async redirectToSalesPackageByName(salesPackageName) {
      const response = await SalesPackageService.getSalesPackageDataByName(salesPackageName);
      const salesPackage = response.data.salesPackage;
      const salesPackageId = salesPackage.items[0].id;

      await this.$router.push({
        name: 'SalesPackageDetails',
        params: { id: salesPackageId }
      });
    }
  }
};
</script>

<style scoped>
.list-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.list-table td, .list-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.list-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.list-table tr:hover {
  background-color: #ddd;
}

.list-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.fm-link {
  text-decoration: underline;
  color: blue;
}

.pointer {
  cursor: pointer;
}
</style>
